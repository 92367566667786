<template>
  <container>
    <div slot="left_box_content">
      <component :is="curLeftComponent"></component>
    </div>
    <div slot="right_box_content">
      <component :is="curRightComponent"></component>
    </div>
    <div slot="bottom_box_content">
      <component :is="curBottomComponent"></component>
    </div>
  </container>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// // 引入store模块
import ownerOrderStoreModule from '@/store/modules/order'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部组件
    IndexTable: () => import('./components/List'),
    Detail: () => import('./components/Detail'),
    MaterialManage: () => import('./components/material/MaterialManage'),
    // 左侧组件
    LeftList: () => import('./components/material/List'),
    // 右侧组件
    Record: () => import('./components/material/Record')
  },
  created () {
    if (!this.$store.hasModule('ownerOrder')) {
      this.$store.registerModule('ownerOrder', ownerOrderStoreModule)
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setShowMap(false)

    this.setBottomComponent('IndexTable')
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('ownerOrder')

    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
